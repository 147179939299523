import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);
export default function Users({ActiveUser,InactiveUSer}) {
  console.log(ActiveUser,InactiveUSer);
 const data = {
    labels: [`Active Users ${ActiveUser}%`, `InActive Users ${InactiveUSer}%`],
    datasets: [
      {
        label: "# of Votes",
        data: [ActiveUser, InactiveUSer],
        backgroundColor: ["#F0C023", "#CB3534"],

        borderWidth: 1,
      },
    ],
  }

  return (
    <>
      <Doughnut className="gender-chart" data={data} />
    </>
  )
}
